import React from "react"
import { heroResolveRichText } from "../../../utils/resolve-rich-text/heroResolveRichText"
import { Heading, Text, TextMono, Button } from "@atoms"
import OptimizedImage from "../../../utils/optimizedImage"
import Breadcrumbs from "./Breadcrumbs"
import {
  HeroStandardNoImage,
  CategoriesWrapper,
  Category,
  BreadcrumbContainer,
} from "../styles/HeroStandard.styled"

const HeroStandardNoReference = ({ component, location, headingColor, fontColor, kickerColor, backgroundColor, seoBreadcrumbs }) => {
  const {
    heading,
    headingSize,
    subheading,
    kicker,
    alignImage,
    callsToAction,
    background,
    featuredImage,
    breadcrumbs,
    breadcrumbTitle,
    categories,
  } = component

  return (
    <HeroStandardNoImage
      alignImage={alignImage}
      className="flex"
      background={backgroundColor}
    >
      {featuredImage &&
        <OptimizedImage className="background-image" image={featuredImage.gatsbyImageData} src={featuredImage?.file?.url} alt="" loading="eager" />
      }
      {breadcrumbs &&
        <BreadcrumbContainer>
          <Breadcrumbs background={background} location={location} seoBreadcrumbs={seoBreadcrumbs} breadcrumbTitle={breadcrumbTitle} />
        </BreadcrumbContainer>
      }
      <div className="container">
        <div className="text-content-wrapper">
          {categories &&
            <CategoriesWrapper>
              {categories?.map((category, index) => (
                <Category key={index} style={{ background: category?.backgroundColor, color: category?.fontColor || '#1B1B1B' }}>{category?.title}</Category>
              ))}
            </CategoriesWrapper>
          }
          {kicker && (
            <TextMono
              style={{
                color: kickerColor,
              }}
            >
              {kicker}
            </TextMono>
          )}
          {heading && (
            <Heading
              style={{
                paddingBottom: "24px",
                color: headingColor
              }}
              headingSize={headingSize}
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Text
              style={{
                color: fontColor
              }}>
              {heroResolveRichText(component)}
            </Text>
          )}
          <div className="btn-wrapper">
            {callsToAction &&
              callsToAction[0]?.link &&
              callsToAction.map(btn => (
                <Button
                  ctaVariant={btn?.variant || "primary"}
                  ctaLink={btn?.link}
                >
                  {btn?.label}
                </Button>
              ))}
          </div>
        </div>
      </div>
    </HeroStandardNoImage>
  )
}

export default HeroStandardNoReference